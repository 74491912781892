<template>
  <div>
    <b-row class="justify-content-center">
      <b-col :cols="user_data.role=='client' ? 8 : 12">
        <h4><feather-icon icon="HelpCircleIcon" /> {{$t('support_list.title')}}</h4>
        <b-card>
          <!-- Table Top -->
          <b-row>

            <!-- Per Page -->
            <b-col
              cols="12"
              class="d-flex align-items-center justify-content-between"
            >
              <div class="d-flex">
                <v-select
                    v-model="perPage"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="perPageOptions"
                    :clearable="false"
                    class="per-page-selector d-inline-block mx-50"
                />
                <div class="d-flex" v-if="user_data.role!='client'">
                  <b-form-group class="ml-50">
                    <v-select
                        id="i-plan"
                        style="min-width: 200px"
                        v-model="support_status"
                        :options="support_status_options"
                        label="title"
                        :clearable="false"
                        :placeholder="$t('feedback_management.node_type')"
                        multiple
                    >
                      <template #option="{ title, icon }" />
                    </v-select>
                  </b-form-group>
                  <b-form-group class="ml-50">
                    <b-input-group>
                      <b-input-group-prepend is-text>
                        <i class="pi pi-calendar"></i>
                      </b-input-group-prepend>
                      <date-range-picker
                          ref="picker"
                          v-model="supportRangeDate"
                          :placeholder="$t('feedback_management.created_feedback_datetime')"
                          style="min-width: 200px"
                          :locale-data="{ firstDay: 1, format: 'dd-mm-yyyy' }"
                      />
                    </b-input-group>
                  </b-form-group>
                </div>
              </div>
              <div>
                <!-- Search -->
                  <b-form-input
                      v-model="searchQuery"
                      size="sm"
                      class="d-inline-block mr-1"
                      :placeholder="$t('core.search')"
                  />
              </div>
            </b-col>
          </b-row>
          <b-row class="mt-50">
            <b-table
              responsive
              :items="table_data"
              :fields="tableColumns"
              primary-key="id"
              class="text-nowrap"
              show-empty
              style="min-height: 500px"
              :sort-by.sync="sortBy"
              :sort-desc.sync="isSortDirDesc"
              :empty-text="$t('core.no_records_found')"
              @row-clicked="onRowClicked"
            >
              <!-- Column: support_status -->
              <template #cell(support_status)="data">
                <b-badge
                    v-if="data.item.support_status=='new'"
                    variant="info"
                >
                  {{ $t("support_detail.support_status_new") }}
                </b-badge>
                <b-badge
                    v-if="data.item.support_status=='inprocessing'"
                    variant="warning"
                >
                  {{ $t("support_detail.support_status_inprocessing") }}
                </b-badge>
                <b-badge
                    v-if="data.item.support_status=='completed'"
                    variant="success"
                >
                  {{ $t("support_detail.support_status_completed") }}
                </b-badge>
                <b-badge
                    v-if="data.item.support_status=='cancelled'"
                    variant="danger"
                >
                  {{ $t("support_detail.support_status_cancelled") }}
                </b-badge>
                <b-badge
                    v-if="data.item.support_status=='customer_replied'"
                    variant="primary"
                >
                  {{ $t("support_detail.support_status_customer_replied") }}
                </b-badge>
                <b-badge
                    v-if="data.item.support_status=='answered'"
                    variant="secondary"
                >
                  {{ $t("support_detail.support_status_answered") }}
                </b-badge>
              </template>

              <!-- Column: Actions -->
              <template #cell(id)="data">
                <div class="text-nowrap">
                  <b-badge variant="info">
                    {{ $t("support_list.detail") }} <feather-icon icon="ChevronRightIcon" size="18"/>
                  </b-badge>
                </div>
              </template>
            </b-table>
          </b-row>
          <div class="card-footer">
            <b-row>
              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-start"
              >
                <!--          <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>-->
              </b-col>
              <!-- Pagination -->
              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-end"
              >
                <b-pagination
                  v-model="currentPage"
                  :total-rows="totalRows"
                  :per-page="perPage"
                  first-number
                  last-number
                  class="mb-0 mt-1 mt-sm-0"
                  prev-class="prev-item"
                  next-class="next-item"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>
              </b-col>
            </b-row>
          </div>
        </b-card>
      </b-col>
      <b-col
        class="mt-1"
        cols="2"
        v-if="user_data.role=='client'"
      >
        <b-card no-body>
          <b-card-header class="p-50 bg-primary justify-content-center">
            <span class="text-white text-bold">{{$t('support_list.new_support_header')}}</span>
          </b-card-header>
          <span class="m-1">{{$t('support_list.new_support_body')}}</span>
          <b-card-footer class="text-center p-25 bg-warning">
            <b-button
              size="sm"
              variant="warning"
              @click="$router.push({ name: 'new_support'})"
            >
              <span class="text-nowrap">{{$t('support_list.btn_new_support')}} <feather-icon icon="ArrowRightCircleIcon" /></span>
            </b-button>
          </b-card-footer>
        </b-card>
      </b-col>
    </b-row>

  </div>
</template>
<script>
import {
  BAlert, BBadge,
  BButton,
  BCard, BCardFooter, BCardHeader,
  BCol, BDropdown, BDropdownItem, BFormFile, BFormGroup, BFormInput, BInputGroup, BInputGroupPrepend, BPagination,
  BRow, BTable,
  VBModal,
  VBToggle,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { getUserData } from '@/auth/utils'
import vSelect from 'vue-select'
import useJwt from '@/auth/jwt/useJwt'
import flatPickr from 'vue-flatpickr-component'
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'

export default {
  components: {
    BTable,
    BPagination,
    BDropdownItem,
    vSelect,
    BBadge,
    BDropdown,
    BAlert,
    BButton,
    BFormFile,
    BCard,
    BCardHeader,
    BCardFooter,
    BInputGroupPrepend,
    BInputGroup,
    BFormInput,
    BFormGroup,
    BRow,
    BCol,
    flatPickr,
    DateRangePicker,
  },
  directives: {
    'b-modal': VBModal,
    'b-toggle': VBToggle,
    Ripple,
  },
  props: {
  },
  data() {
    return {
      supportRangeDate: {
        // startDate: new Date(),
        // endDate: new Date(),
      },
      support_status: [],
      support_status_options: [
        { title: this.$t('support_detail.support_status_new'), value: 'new' },
        { title: this.$t('support_detail.support_status_inprocessing'), value: 'inprocessing' },
        { title: this.$t('support_detail.support_status_customer_replied'), value: 'customer_replied' },
        { title: this.$t('support_detail.support_status_answered'), value: 'answered' },
        { title: this.$t('support_detail.support_status_completed'), value: 'completed' },
        { title: this.$t('support_detail.support_status_cancelled'), value: 'cancelled' },
      ],
      user_data: null,
      perPageOptions: [25, 50, 100],
      searchQuery: null,
      sortBy: null,
      isSortDirDesc: null,
      table_data: null,
      currentPage: 1,
      totalRows: 0,
      perPage: 25,
      tableColumns: [],

    }
  },
  watch: {
    sortBy: { handler() { this.getTableData() } },
    supportRangeDate: { handler() { this.getTableData() } },
    support_status: { handler() { this.getTableData() } },
    isSortDirDesc: { handler() { this.getTableData() } },
    currentPage: {
      handler() {
        this.getTableData()
      },
    },
    searchQuery: {
      handler() {
        this.getTableData()
      },
    },
  },
  created() {
    this.user_data = getUserData()
    if (this.user_data.role == "client"){
      this.tableColumns = [
        { key: 'subject', label: this.$t('support_list.subject'), sortable: true },
        { key: 'support_status', label: this.$t('support_list.status'), sortable: true },
        { key: 'modified', label: this.$t('support_list.last_update'), sortable: true },
        { key: 'id', label: '#' },
      ]
    } else {
      this.support_status = [
        { title: this.$t('support_detail.support_status_new'), value: 'new' },
        { title: this.$t('support_detail.support_status_inprocessing'), value: 'inprocessing' },
        { title: this.$t('support_detail.support_status_customer_replied'), value: 'customer_replied' },
      ]
      this.tableColumns = [
        { key: 'company.company_name', label: this.$t('support_list.company_name'), sortable: true },
        { key: 'request_user.name', label: this.$t('support_list.name'), sortable: true },
        { key: 'subject', label: this.$t('support_list.subject'), sortable: true },
        { key: 'support_status', label: this.$t('support_list.status'), sortable: true },
        { key: 'modified', label: this.$t('support_list.last_update'), sortable: true },
        { key: 'id', label: '#' },
      ]
    }
    this.getTableData()
  },
  mounted() {},
  methods: {
    onRowClicked(row) {
      this.$router.push({ name: 'support_detail', params: { id: row.id }})
    },
    getTableData() {
      const filtred_support_status_list = []
      this.support_status.forEach(item => { filtred_support_status_list.push(item.value) })
      useJwt.getSupportList({
        // center_type: this.filter_center_type.value,
        page: this.currentPage,
        page_size: this.perPage,
        search: this.searchQuery,
        sortBy: this.sortBy,
        sortDesc: this.isSortDirDesc,
        support_status__in: filtred_support_status_list.join(', '),
        created__gte: this.supportRangeDate.startDate,
        created__lte: this.supportRangeDate.endDate,
      })
        .then(response => {
          this.table_data = response.data.results
          this.totalRows = response.data.count
        })
        .catch(error => {
          console.log(error.response)
        })
    },
  },
}
</script>
<style>

</style>
